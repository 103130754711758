<template>
  <router-view />
</template>

<script>
export default {};
</script>
<style>
body {
  background: #232321;
}
</style>
